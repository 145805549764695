@forward '~@prio365/prio365-react-library/lib/index.scss';
@forward '../../components/Timeline/styles/index.scss';

.prio-filter-bar {
  & .prio-date-picker {
    color: var(--prioCl-color-base-primary-default);
    border: 1px solid transparent;
    &-open {
      background: var(--prioCl-color-base-primary-default);
      color: var(--prioCl-color-base-white-default);

      & .prio-date-picker-suffix-icon {
        color: var(--prioCl-color-application-typography-inverse);
      }
    }
    &-filled {
      border: 1px solid var(--prioCl-color-base-primary-default);
      background-color: var(--prioCl-color-base-blue-20);

      #prefix {
        color: var(--prioCl-color-application-typography-default);
      }
    }
  }
}
