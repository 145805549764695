.prio-timeline-row-item {
  position: absolute;
  overflow: visible;
  display: flex;
  flex-direction: row;
  align-items: center;

  &[isresizing='true']
    .prio-timeline-row-item-content
    .prio-timeline-row-item-grabber {
    visibility: visible;
  }

  &:hover .prio-timeline-row-item-content .prio-timeline-row-item-grabber {
    visibility: visible;
  }

  &[isresizing='true'] .prio-timeline-row-item-content {
    background-color: #556471;
  }

  &[isdragging='true'] .prio-timeline-row-item-content {
    background-color: #556471;
  }

  &-disabled {
    &:hover .prio-timeline-row-item-content .prio-timeline-row-item-grabber {
      visibility: hidden;
    }

    & .prio-timeline-row-item-content {
      background-color: #556471;
    }
  }

  &-title {
    flex: 1;
    overflow: hidden;
  }

  &-shallowPopover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: var(--prioCl-spacing-small) 0;
    color: var(--prioCl-color-application-typography-inverse);
  }

  &-shallowPopoverContent {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 5;
  }

  &-popover {
    position: absolute;
    cursor: pointer;
    min-width: 32px;
    min-height: 32px;
    background-color: #556471;
    box-shadow:
      var(--prioCl-box-shadow-regular),
      0 10px 10px rgb(0 0 0 / 25%);
    visibility: hidden;
    border-radius: var(--prioCl-border-radius-regular);
    display: flex;
    align-items: center;
    z-index: 9;
  }

  &-popoverTriangle {
    position: absolute;
    left: calc(50% - 8px);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }
}

.prio-timeline-row-item-content {
  position: relative;
  border-radius: var(--prioCl-border-radius-regular);
  background-color: var(--prioCl-color-application-background-dark);
  color: var(--prioCl-color-application-typography-inverse);
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  transition: background-color 200ms;
}

.prio-timeline-row-item-grabber {
  height: 100%;
  visibility: hidden;
  z-index: 10;

  &Icon {
    height: calc(100% - var(--prioCl-spacing-regular));
    margin: var(--prioCl-spacing-small);
    border-left: 4px double var(--prioCl-color-application-typography-inverse);
  }
}
